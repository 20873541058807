import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Route, { PermissionRoute, loadable, getRedirect } from '@common/components/route';
import Permission from '@common/components/permission';
import RouteNotFound from '@common/components/route-not-found';
import NavigationBar from '@common/components/navigation-bar';
import ErrorComponent from '@common/components/error';
import TopBar from '@common/components/top-bar';
import { Icon } from '@common/components/icon';

import { EComponentTypes, EPermissions, EPlanPackageConfig, PaymentStatus } from '@common/definitions';

import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { useEnabledComponents } from '@common/hooks/use-enabled-components';
import * as organisationSelector from '../organisation/selectors/organisation';
import * as networkSelector from '../network/selectors/network';
import * as userSelector from '../core/selectors/logged-user';
import { UserProfileTypes } from '../core/definitions';
import AlertBarPaymentOverdue from './components/alert-bar/alert-bar-payment-overdue';

require('./styles.scss');

// Create loadable routes
const SetupContainer = loadable(() => import('./containers/setup'));
const StructureContainer = loadable(() => import('./containers/structure/structure'));
const ContentContainer = loadable(() => import('./containers/content'));
const UsersContainer = loadable(() => import('./containers/users'));
const UserContainer = loadable(() => import('./containers/user'));
const UsersImportContainer = loadable(() => import('./containers/import'));
const SettingsContainer = loadable(() => import('./containers/settings'));
const PermissionsContainer = loadable(() => import('./containers/permissions'));
const SurveyContainer = loadable(() => import('../survey/template'));
const FormsContainer = loadable(() => import('../forms/router'));
const LearningContainer = loadable(() => import('../learning/template'));
const AnalyticsContainer = loadable(() => import('../analytics'));
const ProfileContainer = loadable(() => import('../core/containers/profile'));
const ChatContainer = loadable(() => import('../chat/containers/chat'));
const EventsRouter = loadable(() => import('../events/router'));
const DocumentsContainer = loadable(() => import('../documents/containers/documents-container'));

class AdminTemplate extends React.Component {
  static getDerivedStateFromError(error) {
    return { error };
  }

  constructor() {
    super();

    this.state = {
      error: null,
    };

    this.handleLogout = this.handleLogout.bind(this);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname && this.state.error) {
      this.state.error = null;
    }
  }

  static props;

  handleLogout() {
    const { logout, history } = this.props;

    logout();
    history.push('/auth/login');
  }

  render() {
    const { error } = this.state;
    const {
      history, match, t,
      loggedUser, organisation, networks, permissions: allPermissions,
      progress, unread, billing,
      isAvailable: canCreateEvents, showUpgradeModal,
    } = this.props;

    const hasPermission = ({ name, component: requiredComponent }) => name.some((item) => allPermissions.includes(item))
      && (requiredComponent ? organisation.enabled_components.some((component) => component === requiredComponent) : true);

    const pulseSurveysEnabled = hasPermission({
      name: [EPermissions.ORGANISATION_SURVEYS_VIEW],
      component: EComponentTypes.PULSE_SURVEYS
    });

    const items = [
      organisation.trial && progress.percentage < 100 && {
        permissions: {
          name: [EPermissions.ORGANISATION_SETUP_STEPS_UPDATE],
        },
        path: '/admin/setup',
        tooltip: t('core:tab_setup'),
        icon: 'input',
        id: 'admin_setup',
      }, {
        permissions: {
          component: EComponentTypes.ANALYTICS,
          name: [
            EPermissions.ORGANISATION_USER_STATISTICS_VIEW,
            EPermissions.ORGANISATION_COMMUNICATION_STATISTICS_VIEW,
            EPermissions.ORGANISATION_EXCHANGE_STATISTICS_VIEW
          ],
        },
        path: '/admin/analytics',
        tooltip: t('core:tab_statistics'),
        icon: 'analytics',
        id: 'admin_analytics',
      }, {
        permissions: {
          name: [EPermissions.ORGANISATION_ORGANISATION_MESSAGES_VIEW, EPermissions.ORGANISATION_CHANNELS_VIEW],
        },
        path: '/admin/content',
        tooltip: t('core:tab_content'),
        icon: 'send',
        id: 'admin_content',
      },
      (
        (
          hasPermission({
            component: EComponentTypes.ACADEMY,
            name: [EPermissions.ORGANISATION_ACADEMY_COURSES_VIEW]
          }) ||
          hasPermission({
            component: EComponentTypes.ONBOARDING,
            name: [EPermissions.ORGANISATION_ONBOARDING_COURSES_VIEW]
          })
        ) && {
          permissions: {
            name: [
              EPermissions.ORGANISATION_ONBOARDING_COURSES_VIEW,
              EPermissions.ORGANISATION_ACADEMY_COURSES_VIEW
            ],
          },
          path: '/admin/learning',
          tooltip: t('core:tab_elearning'),
          icon: 'school',
          id: 'admin_learning',
        }
      ), {
        permissions: {
          name: [EPermissions.ORGANISATION_EVENTS_VIEW_ALL],
          component: EComponentTypes.EVENTS,
        },
        path: '/admin/events',
        tooltip: t('core:tab_events'),
        icon: 'event',
        id: 'admin_events',
        onClick: (e) => {
          if (!canCreateEvents) {
            e.preventDefault();
            showUpgradeModal();
          }
        },
      }, {
        permissions: {
          name: [EPermissions.ORGANISATION_SURVEYS_VIEW],
          component: EComponentTypes.SURVEYS,
        },
        path: pulseSurveysEnabled ?
          '/admin/surveys/pulse-overview' :
          '/admin/surveys',
        tooltip: t('core:tab_surveys'),
        icon: 'chat',
        id: 'admin_surveys',
        isActive: (m, location) => location.pathname.startsWith('/admin/surveys'),
      }, {
        permissions: {
          name: [
            EPermissions.ORGANISATION_FORMS_VIEW_ALL,
            EPermissions.ORGANISATION_CREATOR_FORMS_VIEW
          ],
          component: EComponentTypes.FORMS,
        },
        path: '/admin/forms',
        tooltip: t('core:tab_forms'),
        icon: 'assignment',
        id: 'admin_forms',
      }, {
        permissions: {
          name: [EPermissions.ORGANISATION_DOCUMENTS_VIEW_ALL],
          component: EComponentTypes.DOCUMENTS,
        },
        path: '/admin/documents',
        tooltip: t('core:tab_documents_admin'),
        icon: 'folder',
        id: 'admin_documents',
      }, {
        permissions: {
          name: [EPermissions.ORGANISATION_USERS_VIEW],
        },
        path: '/admin/users',
        tooltip: t('core:tab_employees'),
        icon: 'group',
        id: 'admin_users',
      }, {
        permissions: {
          name: [EPermissions.ORGANISATION_ORGANISATION_ADMINS_VIEW, EPermissions.ORGANISATION_ROLES_VIEW],
        },
        path: '/admin/admins',
        tooltip: t('core:tab_admins'),
        icon: 'shield',
        id: 'admin_admins',
      }, {
        permissions: {
          name: [EPermissions.ORGANISATION_ORGANISATIONS_FUNCTIONS_VIEW],
        },
        path: '/admin/structure',
        tooltip: t('core:tab_structure'),
        icon: 'account_tree',
        id: 'admin_structure',
      }, {
        type: 'divider',
      }, {
        permissions: {
          name: [EPermissions.ORGANISATION_ORGANISATIONS_UPDATE],
        },
        path: '/admin/settings/general',
        tooltip: t('core:tab_organisation_settings'),
        icon: 'settings',
        id: 'admin_settings',
        isActive: (m, location) => location.pathname.indexOf('/admin/settings') === 0,
      },
    ].filter((item) => !!item);

    const redirect = getRedirect(items, hasPermission);

    return (
      <div id="AdminTemplate">
        <NavigationBar admin organisation={organisation} onHome={() => history.push('/admin')}>
          {
            items
              .filter((item) => item.id !== 'admin_setup')
              .map(({ type, permissions, log, ...props }) => (
                type === 'divider' ?
                  <NavigationBar.Divider key="divider" /> :
                  (
                    <Permission key={props.path} {...permissions} log={log}>
                      <NavigationBar.Item {...props} />
                    </Permission>
                  )
              ))
          }
        </NavigationBar>
        <div id="Content">
          {
            billing?.data?.payment_status === PaymentStatus.OVERDUE && (
              <AlertBarPaymentOverdue organisation={organisation} />
            )
          }
          <TopBar
            admin
            loggedUser={loggedUser}
            organisation={organisation}
            networks={networks}
            history={history}
            onOpenProfile={(path) => history.push(`/admin/profile/about${path || ''}`)}
            onLogout={this.handleLogout}
            onSelectNetwork={this.props.selectNetwork}
          >
            <NavLink
              to="/admin/conversations"
              className="TopBar__Trigger TopBar__Trigger--icon"
              activeClassName="TopBar__Trigger--open"
            >
              <Icon type="chat__filled" />
              {unread.chat ? <span className="TopBar__Trigger__Unread">{unread.chat}</span> : null}
            </NavLink>
          </TopBar>
          <div className="Content__Wrapper">
            {error && (
              <ErrorComponent error={error} />
            )}
            {!error && (
              <Switch>
                <PermissionRoute
                  path="/admin/setup"
                  component={SetupContainer}
                  permission={{ name: EPermissions.ORGANISATION_SETUP_STEPS_UPDATE }}
                />
                <PermissionRoute
                  path="/admin/analytics"
                  component={AnalyticsContainer}
                  permission={{
                    name: [
                      EPermissions.ORGANISATION_USER_STATISTICS_VIEW,
                      EPermissions.ORGANISATION_COMMUNICATION_STATISTICS_VIEW,
                      EPermissions.ORGANISATION_EXCHANGE_STATISTICS_VIEW
                    ]
                  }}
                />
                <PermissionRoute
                  path="/admin/content"
                  component={ContentContainer}
                  permission={{
                    name: [
                      EPermissions.ORGANISATION_ORGANISATION_MESSAGES_VIEW,
                      EPermissions.ORGANISATION_CHANNELS_VIEW
                    ]
                  }}
                />
                <PermissionRoute
                  path={['/admin/users/filter/:filter', '/admin/users']}
                  exact
                  component={UsersContainer}
                  permission={{ name: EPermissions.ORGANISATION_USERS_VIEW }}
                />
                <PermissionRoute
                  path="/admin/users/import"
                  component={UsersImportContainer}
                  permission={{
                    name: [
                      EPermissions.ORGANISATION_USERS_VIEW,
                      EPermissions.ORGANISATION_USERS_CREATE
                    ]
                  }}
                />
                <Route
                  path={['/admin/users/:userId/edit', '/admin/users/:userId']}
                  component={UserContainer}
                />
                <PermissionRoute
                  path="/admin/learning"
                  component={LearningContainer}
                  permission={{
                    name: [
                      EPermissions.ORGANISATION_ONBOARDING_COURSES_VIEW,
                      EPermissions.ORGANISATION_ACADEMY_COURSES_VIEW
                    ]
                  }}
                />
                <PermissionRoute
                  path="/admin/structure"
                  component={StructureContainer}
                  permission={{
                    name: [EPermissions.ORGANISATION_ORGANISATIONS_FUNCTIONS_VIEW]
                  }}
                />
                <PermissionRoute
                  path="/admin/surveys"
                  component={SurveyContainer}
                  permission={{
                    name: [EPermissions.ORGANISATION_SURVEYS_VIEW],
                    component: EComponentTypes.SURVEYS
                  }}
                />
                <PermissionRoute
                  path="/admin/forms"
                  component={FormsContainer}
                  permission={{
                    name: [
                      EPermissions.ORGANISATION_FORMS_VIEW_ALL,
                      EPermissions.ORGANISATION_CREATOR_FORMS_VIEW
                    ],
                    component: EComponentTypes.FORMS
                  }}
                />
                <PermissionRoute
                  path="/admin/settings"
                  component={SettingsContainer}
                  permission={{ name: EPermissions.ORGANISATION_ORGANISATIONS_UPDATE }}
                />
                <PermissionRoute
                  path={['/admin/admins/roles', '/admin/admins']}
                  component={PermissionsContainer}
                  permission={{
                    name: [
                      EPermissions.ORGANISATION_ORGANISATION_ADMINS_VIEW,
                      EPermissions.ORGANISATION_ROLES_VIEW
                    ]
                  }}
                />
                <PermissionRoute
                  path="/admin/events"
                  component={EventsRouter}
                  permission={{
                    name: [EPermissions.ORGANISATION_EVENTS_VIEW_ALL],
                    component: EComponentTypes.EVENTS
                  }}
                />
                <PermissionRoute
                  path="/admin/documents"
                  component={DocumentsContainer}
                  permission={{
                    name: [EPermissions.ORGANISATION_DOCUMENTS_VIEW_ALL],
                    component: EComponentTypes.DOCUMENTS
                  }}
                />
                <Route path="/admin/profile">
                  <ProfileContainer userId={loggedUser.id} type={UserProfileTypes.OWN} />
                </Route>
                <Route
                  path={[
                    '/admin/conversations/:conversationId',
                    '/admin/conversations',
                    '/admin/conversations/filter/:filter/:conversationId',
                    '/admin/conversations/filter/:filter',
                    '/admin/conversations/users/:userId',
                  ]}
                  exact
                  render={() => <ChatContainer basePath="/admin/conversations" />}
                />
                {redirect && match.path !== redirect && <Redirect exact from="/admin" to={redirect} />}
                <Route component={RouteNotFound} />
              </Switch>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organisation: organisationSelector.selected(state),
  network: networkSelector.selected(state),
  progress: organisationSelector.setup(state),
  networks: userSelector.networks(state),
  loggedUser: userSelector.selected(state),
  permissions: userSelector.permissions(state),
  unread: state.notifications,
  billing: state.organisation.billing,
});

const mapDispatchToProps = {
  logout: require('../authentication/actions/logout').default,
  selectNetwork: require('../network/actions/select-network').default,
};

const WrappedAdminTemplate = (props) => (
  <AdminTemplate
    {...props}
    {...useIsAvailableInPlanPackage(EPlanPackageConfig.EVENTS)}
    {...useEnabledComponents({ hasHome: EComponentTypes.HOME })}
  />
);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WrappedAdminTemplate));
